<template>
  <div class="tour-card">
    <div class="header">
      <h4>{{ event.name }}</h4>
      <p>{{ eventDateFormater(event.startDate) }}</p>
    </div>
    <div class="sub-header">
      <div class="status" v-if="tour.status == 'COMPLETED'">
        <span><On color="midnight" /></span> Finished
      </div>
      <div
        class="archiver"
        v-if="tour.status == 'COMPLETED'"
        @click="toggleArchivedStatus()"
      >
        <span>{{ tour.isArchived ? "Unarchive" : "Archive" }}</span>
      </div>
      <div class="status" v-else-if="tour.isPublished">
        <span><On /></span> Published
      </div>
      <div class="status" v-else>
        <span><Off /></span> Unpublished
      </div>
    </div>
    <div class="next-event" v-if="tour.status !== 'COMPLETED'">
      <div class="event-status">
        <template v-if="showRegOpeningDate">
          <RegistrationOpen color="sunrise" />
          <p>
            Opens
            {{ regOpenDateFormater(event.registrationOpenAt) }}
          </p>
        </template>
        <template v-else>
          <template v-if="!event.isRegistrationOpen">
            <Off />
            <p>Closed</p>
          </template>

          <template v-if="event.isRegistrationOpen">
            <RegistrationOpen color="grass" />
            <p>Open</p>
          </template>
          <On color="ocean" class="confirmed" />
          <p>{{ event.playersCount }}</p>
          <WaitingList class="waitinglist" />
          <p>
            {{ waitingListLength }}
          </p>
        </template>
      </div>
      <div class="card-label ocean" v-if="event.pdgaType">
        {{ event.pdgaType.name }}
      </div>
    </div>
    <div
      class="view-event"
      @click="
        $router.push({
          name: 'event-overview',
          params: { eventId: event.id },
        })
      "
    >
      View event
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WaitingList from "@/assets/icons/WaitingList";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";

export default {
  name: "OrganizerOneOffEventOverviewCard",
  props: ["tour"],
  data() {
    return {
      expanded: false,
    };
  },
  components: {
    RegistrationOpen,
    On,
    Off,
    WaitingList,
  },
  computed: {
    waitingListLength() {
      return this.event.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
    showRegOpeningDate() {
      if (this.event.isRegistrationOpen) return false;
      if (moment(this.event.registrationOpenAt).isAfter()) return true;
      return false;
    },
    event() {
      return this.tour.events[0];
    },
  },
  methods: {
    async toggleArchivedStatus() {
      try {
        await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation UpdateTour($UpdateTour: UpdateTourInput!) {
                UpdateTour(tourId:"${this.tour.id}", input: $UpdateTour, asRole:TOUR_ADMIN){
                  id
                }
              }
            `,
            variables: {
              UpdateTour: {
                isArchived: !this.tour.isArchived,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: this.tour.isArchived
            ? "Succsessfully unarchived"
            : "Succsessfully archived",
          type: "success",
        });

        this.$emit("refresh");
      } catch (e) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "success",
        });
      }
    },
    regOpenDateFormater(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
    eventDateFormater(date) {
      return moment(date).format("D MMM");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.tour-card {
  border-radius: 10px;
  border: 1px solid $sleet;
  background: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  transition: all ease 0.3s;

  .header {
    padding: 18px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    svg {
      transition: all ease 0.3s;
      &:hover {
        cursor: pointer;
        transform: rotate(90deg);
      }
    }

    h4 {
      margin: 0;
      font-size: 18px;
      max-width: 75%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .sub-header {
    margin: 16px 0 16px 0;
    padding: 0 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .archiver {
      cursor: pointer;
      color: $twilight;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

    .status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      span {
        margin-right: 10px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .events-toggle {
    cursor: pointer;
    user-select: none;
    height: 56px;
    border-top: 1px solid $fog;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      color: $ocean;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .next-event {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .add-event {
      margin-right: 20px;
    }

    .card-label {
      margin-right: 20px;
    }

    .event-status {
      padding: 0 20px;
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-size: 14px;
      }

      .confirmed,
      .waitinglist {
        margin-left: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .view-event {
    width: auto;
    height: 48px;
    margin: 20px 20px 22px 20px;
    background: $mist;
    border-radius: 6px;
    font-size: 16px;
    @include Gilroy-Bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      background: $fog;
    }
  }
  .event {
    border-top: 1px solid $fog;
    padding: 0 20px;
    display: flex;
    height: 56px;
    align-items: center;
    transition: all ease 0.3s;
    cursor: pointer;
    max-width: 100%;

    &:hover {
      background: $mist;
    }

    .icon {
      width: 20px;
      margin-right: 10px;
      svg {
        height: 20px;
        width: 20px;
      }
    }

    .name {
      max-width: calc(100% - 50px);
      p {
        margin: 0;
        @include Gilroy-Bold;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .nav-icon {
      width: 14px;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tour-card {
    .header {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
        min-width: 60px;
        text-align: right;
      }
    }
    .sub-header {
      .archiver {
        font-size: 16px;
      }
      .status {
        font-size: 16px;
      }
    }
    .next-event {
      padding: 0;
      .next-event-row {
        .date {
          font-size: 16px;
        }
      }
      .name {
        padding: 10px 0 10px 0;
        p {
          font-size: 18px;
        }
      }
      .event-status {
        p {
          font-size: 16px;
        }
      }
    }
    .view-tour {
      margin: 24px 20px 26px 20px;
    }
  }
}
</style>
