<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-564 -347)">
      <circle class="a" cx="12" cy="12" r="12" transform="translate(564 347)" />
    </g>
    <path
      class="b"
      d="M2921.452,775.047h7v-8"
      transform="translate(-2916.452 -763.047)"
    />
  </svg>
</template>

<script>
export default {
  name: "WaitingList",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ff6e46;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>