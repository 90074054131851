<template>
  <main v-if="loaded">
    <template v-if="nothingBuilt">
      <h3 class="page-title">Hi, {{ $store.getters.userInfo.firstName }}</h3>

      <div class="nothing-built">
        <p>
          It doesn’t look like you’ve built any tours, leagues or events yet.
          Start building when you’re ready.
        </p>
        <StandardSolidButton
          title="Start building"
          :fluid="false"
          :desktopFluidity="true"
          @click="$router.push({ name: 'builder' })"
        />
      </div>
    </template>
    <template v-else>
      <h3 class="page-title">
        Welcome back, {{ $store.getters.userInfo.firstName }}
        <StandardSolidButton
          title="Build"
          :fluid="false"
          :desktopFluidity="true"
          @click="$router.push({ name: 'builder' })"
        />
      </h3>
      <h6 v-if="tours.length > 0">Your tours</h6>
      <div class="tours-container">
        <OrganizerTourOverviewCard
          :tour="tour.tour"
          v-for="tour in tours"
          :key="tour.id"
          @refresh="$store.dispatch('loadManagedEvents')"
        />
      </div>
      <h6 v-if="oneOffEvents.length > 0">Your one-off events</h6>
      <div class="one-off-container">
        <OrganizerOneOffEventOverviewCard
          :tour="tour.tour"
          v-for="tour in oneOffEvents"
          :key="tour.id"
          @refresh="$store.dispatch('loadManagedEvents')"
        />
      </div>
      <StandardSolidButton
        class="mobile-only"
        title="Build"
        :fluid="false"
        :desktopFluidity="true"
        @click="$router.push({ name: 'builder' })"
      />
      <h6 @click="archivedExpanded = !archivedExpanded" class="archive">
        Archived tours
        <span
          ><Chevron2 :direction="archivedExpanded == true ? 'up' : 'down'"
        /></span>
      </h6>
      <slide-up-down
        :active="archivedExpanded"
        :duration="300"
        class="archived-list"
      >
        <template v-for="tour in archive">
          <OrganizerTourOverviewCard
            v-if="tour.tour.events.length > 1"
            :tour="tour.tour"
            :key="tour.id"
            @refresh="$store.dispatch('loadManagedEvents')"
          />
          <OrganizerOneOffEventOverviewCard
            v-else
            :tour="tour.tour"
            :key="tour.id"
            @refresh="$store.dispatch('loadManagedEvents')"
          />
        </template>
      </slide-up-down>
    </template>
  </main>
</template>

<script>
import { orderBy } from "lodash";
import OrganizerTourOverviewCard from "@/components/OrganizerTourOverviewCard";
import OrganizerOneOffEventOverviewCard from "@/components/OrganizerOneOffEventOverviewCard";
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";

export default {
  name: "OrganizerManagerOverview",
  components: {
    OrganizerTourOverviewCard,
    OrganizerOneOffEventOverviewCard,
    SlideUpDown,
    Chevron2,
    StandardSolidButton,
  },
  data() {
    return {
      archivedExpanded: false,
    };
  },
  watch: {},
  computed: {
    nothingBuilt() {
      return this.managedEvents.data.length == 0 ? true : false;
    },
    tours() {
      let tours = this.managedEvents.data.filter(
        (tour) => tour.tour.events.length > 1 && tour.tour.isArchived == false
      );

      let sortedTours = orderBy(tours, (tour) => tour.tour.name, ["asc"]);
      return sortedTours;
    },
    archive() {
      let archived = this.managedEvents.data.filter(
        (tour) => tour.tour.isArchived
      );

      let sortedArchive = orderBy(archived, (tour) => tour.tour.name, ["asc"]);
      return sortedArchive;
    },
    oneOffEvents() {
      let oneOffEvents = this.managedEvents.data.filter(
        (tour) => tour.tour.events.length == 1 && tour.tour.isArchived == false
      );

      let sortedOneOffEvents = orderBy(
        oneOffEvents,
        (tour) => tour.tour.events[0].name,
        ["asc"]
      );
      return sortedOneOffEvents;
    },
    loaded() {
      return this.managedEvents.loaded;
    },
    managedEvents() {
      return this.$store.getters.managedEvents;
    },
    nextEventsInTours() {
      let events = [];
      this.tours.forEach((tour) => {
        if (tour.tour.nextEvent != null) {
          events.push(tour.tour.nextEvent);
        }
      });
      return events;
    },
    dashboardData() {
      let dashboardData = {
        tourCount: 0,
        eventCount: 0,
        roundsCount: 0,
        playersCount: 0,
        nextEvent: null,
        latestEvent: null,
        mostPopularEvent: {},
      };

      let mostPopularEventCounter = 0;

      this.tours.forEach((tour) => {
        dashboardData.tourCount++;

        tour.tour.events.forEach((event) => {
          event.rounds.forEach((round) => {
            dashboardData.roundsCount++;
          });

          dashboardData.eventCount++;
          dashboardData.playersCount += event.playersCount;
          if (event.playersCount >= mostPopularEventCounter) {
            dashboardData.mostPopularEvent = event;
            mostPopularEventCounter = event.playersCount;
          }
        });
      });

      return dashboardData;
    },

    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
  },
  methods: {},
  beforeMount() {
    if (this.isUserLoggedIn == false) {
      this.$router.push({ name: "login", params: { source: "manage" } });
    }
    if (!this.showMobileMenu) {
      this.showSubPagesOnMobile = true;
    }
  },
  mounted() {
    if (this.isUserLoggedIn) {
      this.$store.dispatch("loadManagedEvents");
    } else {
      this.loading = false;
    }
  },
};
</script>


<style scoped lang="scss">
.one-off-container,
.tours-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.nothing-built {
  margin-top: 32px;
  padding: 0 12px;
  p {
    font-size: 16px;
  }

  button {
    margin-top: 32px;
  }
}
.page-title {
  padding-left: 10px;
  margin-bottom: 0px;
  font-size: 20px;
  button {
    display: none;
  }
}
main {
  margin: 32px 8px;

  .tour-card {
    width: 100%;
  }

  h6 {
    font-size: 16px;
    @include Gilroy-Regular;
    margin: 32px 0 24px 0;
    padding-left: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;

    &.archive {
      cursor: pointer;
      span {
        font-size: 14px;
        color: $ocean;
      }
    }
  }
}
@media (orientation: landscape) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .mobile-only {
    display: none;
  }
  .nothing-built {
    margin-top: 32px;
    padding: 0;
    margin-left: 1.65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p {
      font-size: 18px;
      margin: 0;
    }

    button {
      margin-top: 0px;
    }
  }
  main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;

    .page-title {
      width: 100%;
      padding-left: 0;
      padding-bottom: 50px;
      border-bottom: 1px solid $sleet;
      margin-bottom: 18px;
      font-size: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        display: unset;
      }
    }
    h6 {
      font-size: 18px;
      margin-bottom: 32px;
      margin-top: 32px;
      padding-left: 0;
      justify-content: flex-start;

      &.archive {
        cursor: pointer;
        span {
          font-size: 16px;
          color: $ocean;
          margin-left: 10px;
        }
      }
    }

    .tour-card {
      width: calc(33.3% - 30px);
      margin: 0 45px 32px 0;
    }
  }

  .one-off-container,
  .tours-container,
  .archived-list {
    .tour-card:nth-child(3n) {
      margin-right: 0;
    }
  }
  .archived-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>
